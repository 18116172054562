import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { LoggerService } from '../../../services/LoggerService';
import SharpsService from '../../../services/SharpsService';
import SharpsFormView from '../../../views/SharpsFormView';
import type { SharpsForm } from '../../../services/SharpsService';
import { HttpError } from '../../../services/HttpService';
import NotificationService, { DefaultNotificationMessage, NotificationTypes } from '../../../services/NotificationService';
import ValidationService from '../../../services/ValidationService';

const logger = LoggerService.getLogger('MemberSubmissionSharps');

function MemberSubmissionSharpsSelfReporting(props) {
    const [checked, setChecked] = React.useState(false);
    const [index, setIndex] = React.useState(1);
    const [disabled, setDisabled] = React.useState(false);
    const [form, setForm] = React.useState(({
        allIqviaData: [],
        step1A: [],
        step1B: [],
        step1C: [],
        step2A: [],
        step2B: [],
        dueDate: null,
        submitted: false,
        companyId: 0,
        companyName: null,
        dateSubmitted: null
    }: SharpsForm));

    useEffect(() => {
        fetchForm();
    }, [props.companyId, props.year, props.reloadToggle]);

    function fetchForm() {
        SharpsService.getSelfReportedSharps(props.companyId, props.year)
            .then((res: SharpsForm) => {
                setForm(res);
            }).catch((err) => {
                logger.error(`Unable to get memberSubmissions for ${props.companyId}`, err);
            });
    }

    function handleSave() {
        postForm(false);
    }

    function handleSubmit() {
        postForm(true);
    }

    function postForm(isSubmitting) {
        const message = isSubmitting ? DefaultNotificationMessage.submitted : DefaultNotificationMessage.saved;
        const method = isSubmitting ? SharpsService.AdminSubmitSharpsForm : SharpsService.AdminSaveSharpsForm;

        setDisabled(true);
        method(form)
            .then(() => {
                setDisabled(false);
                fetchForm();
                props.refresh();
                NotificationService.fireNotification(NotificationTypes.success, message);
            })
            .catch((error: HttpError) => {
                setDisabled(false);
                NotificationService.fireNotification(NotificationTypes.error, `Error ${error.message}`);
            });
    }

    function addSelectedData(step, substep, data) {
        let f = form;
        let steps = getStep(f, step);
        data.forEach((elm) => {
            elm.sectionStep = step;
            elm.subStep = substep;
        });
        steps = steps.concat(
            data.filter(a => steps.map(b => b.iqviaDataId).indexOf(a.iqviaDataId) < 0));
        f = assignToStep(f, steps, step);
        setNewForm(f);
    };

    function removeSelectedData(step, data) {
        let f = form;
        let steps = getStep(f, step);
        steps = steps.filter(a => data.map(b => b.iqviaDataId).indexOf(a.iqviaDataId) < 0);
        f = assignToStep(f, steps, step);
        setNewForm(f);
    };

    function addAdditionalData(step, substep, data) {
        // Check data
        if (data === null) {
            return false;
        }

        let f = form;
        let steps = getStep(f, step);
        steps.push({
            id: null,
            productName: data.productName,
            units: data.units,
            notes: data.notes,
            sectionStep: step,
            subStep: substep,
            index: index
        });
        const newIndex = index + 1;
        setIndex(newIndex);
        steps = [].concat(steps);
        f = assignToStep(f, steps, step);
        setNewForm(f);
        return true;
    };

    function removeAdditionalData(item, step) {
        let f = form;
        let steps = getStep(f, step);
        steps.splice(getAdditionalDataItemPosition(item, steps), 1);
        steps = [].concat(steps);
        f = assignToStep(f, steps, step);
        setNewForm(f);
    };

    function updateAdditionalData(val, item, field, step) {
        let f = form;
        let steps = getStep(f, step);

        if (!ValidationService.SharpsAdditionalDataIsValid(field, val, true)) {
            return;
        }

        steps[getAdditionalDataItemPosition(item, steps)][field] = val;
        f = assignToStep(f, steps, step);
        setNewForm(f);
    };

    function setNewForm(f: SharpsForm) {
        setForm({
            allIqviaData: f.allIqviaData,
            step1A: f.step1A,
            step1B: f.step1B,
            step1C: f.step1C,
            step2A: f.step2A,
            step2B: f.step2B,
            dueDate: f.dueDate,
            dateCreated: f.dateCreated,
            submitted: f.submitted,
            isLocked: f.isLocked,
            companyId: f.companyId,
            companyName: f.companyName
        });
    };

    function getAdditionalDataItemPosition(item, data) {
        //as we are passing out different data to the different steps, we cannot just use
        //an index to the array.
        //and as we are dealing with two types of data (data from db and created locally),
        //we check based on which one is populated
        if (item.id !== null) { //meaning not created on this page
            //use id to query
            return data.findIndex(elm => elm.id === item.id);
        } else {
            //use index to query
            return data.findIndex(elm => elm.index === item.index);
        }
    };

    function getStep(f, step) {
        switch (step) {
            case '1A':
                return f.step1A;
            case '1B':
                return f.step1B;
            case '1C':
                return f.step1C;
            case '2A':
                return f.step2A;
            case '2B':
                return f.step2B;
            default:
                return null;
        }
    };

    function assignToStep(f, steps, step) {
        switch (step) {
            case '1A':
                f.step1A = steps;
                break;
            case '1B':
                f.step1B = steps;
                break;
            case '1C':
                f.step1C = steps;
                break;
            case '2A':
                f.step2A = steps;
                break;
            case '2B':
                f.step2B = steps;
                break;
            default:
                break;
        }
        return f;
    };

    return (
        <SharpsFormView
            form={form}
            checked={checked}
            setChecked={setChecked}
            addSelectedData={addSelectedData}
            removeSelectedData={removeSelectedData}
            addAdditionalData={addAdditionalData}
            removeAdditionalData={removeAdditionalData}
            updateAdditionalData={updateAdditionalData}
            handleSave={handleSave}
            handleSubmit={handleSubmit}
            disabled={disabled}
            readOnly={!props.editable}
            adminView={true}
        />
    );
}

export default MemberSubmissionSharpsSelfReporting;
